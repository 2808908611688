import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp: async ({ name }: { name: string }): Promise<any> => {
    if (name === "@crm/rootapp") {
      return import("./root.component");
    } else {
      return System.import(name);
    }
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

function loadUI() {
  // registerApplication({
  //   name: '@crm/request-access',
  //   app: () => System.import('@crm/request-access') as any,
  //   activeWhen: ['/request-access'],
  // });
  // registerApplication({
  //   name: '@scft/hmt',
  //   app: () => importModule('@scft/hold-management') as any,
  //   activeWhen: ['/VehicleManagement#hold'],
  // });
  applications.forEach(registerApplication);

  layoutEngine.activate();
  start();
}
loadUI();
